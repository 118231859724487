/**
 * Return backend url
 */
export function backendUrl() {
    const { host, protocol } = useRequestURL();

    // Replace first part of host with 'app'
    // Ex: lots.stocklear.fr => app.stocklear.fr
    let crumbs = host.split('.');
    crumbs[0] = 'app';
    let baseUrl = crumbs.join('.');

    return `${protocol}//${baseUrl}`;
}
